import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Survey from './components/Survey';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingTop: theme.spacing(3)
  },
  paper: {
    margin: theme.spacing(5, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HomeView = () => {
  const classes = useStyles();
  let { token } = useParams();
  let query = useQuery();
  let _lang = query.get("lang") ? query.get("lang") : query.get("l");
  let lang = _lang ? _lang : 'ar';
  
  return (
    <Page
      title="AI-Powered Survey Platform"
      className={classes.root}
    >
      <div className={classes.paper}>
        <Grid container>
          <Grid item xs={12} sm container>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width="100%"
              justifyContent="center"
              style={{ paddingTop: "10px" }}
            >
              <Survey lang={lang} token={token} ro={query.get("ro") ? query.get("ro") : false} />
            </Box>
          </Grid>
        </Grid>
      </div>

    </Page >
  );
};

export default HomeView;
